.App {
  background: url(./playground-photo-1.jpg) no-repeat center 40px;
  background-size: cover;
  width: 100%;
  height: 800px;
}

p {
  width: 100%;
  background-color: #ff00ff;
  font-size: 36px;
  letter-spacing: 5px;
  padding: 10px 10px 10px 10px;
  text-align: left;
  color: white;
  font-family: Gotham, Helvetica, sanserif;
  margin: 0;
}